<template>
  <div class="calendarData">
    <div v-if="!infoData" style="padding: 15px;">
      <a-skeleton :paragraph="{ rows: 6 }" />
    </div>

    <div
      class="date-container flex"
      style="align-items: normal;"
      v-if="infoData"
    >
      <div class="date-plug">
        <div class="plug-title flex">
          <div class="date-text">
            <span>{{ __moment__(month).format('YYYY年MM月') }}</span>
            <a-month-picker
              class="month"
              v-model="month"
              :format="'YYYY年MM月'"
            />
          </div>
          <!-- <div class="now-date">
                        <div class="up-btn" @click="_cutDate(1)" :style="cutIndex == 0 ? 'color: #ddd;border-color: #ddd' : ''"><a-icon type="left" /></div>
                        <span>{{cutList[cutIndex]}}</span>
                        <div class="down-btn" @click="_cutDate(2)" :style="cutIndex == 2 ? 'color: #ddd;border-color: #ddd' : ''"><a-icon type="right" /></div>
                    </div> -->
        </div>
        <div class="week-list">
          <div
            class="week-item"
            v-for="item in weekList"
            :key="'ke' + item.value"
            style="height: 25px;"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="week-list">
          <div
            class="week-item"
            v-for="(item, index) in infoData"
            :key="index + 'rr' + item.Date"
            :class="item.DateInfo.Day == selfDate ? 'active' : ''"
            @click="_selectDate(item.DateInfo.Day, index)"
          >
            <span style="line-height: 44px;">{{ item.DateInfo.Day }}</span>
            <div class="num" v-if="item.Num != 0">{{ item.Num }}</div>
          </div>
        </div>
      </div>
      <!-- 联系人列表 -->
      <div
        class="linkman-list"
        style="flex: 1;"
        v-if="infoData && infoData[selectIndex].List.length != 0"
      >
        <div class="linkman-title flex">
          <div class="title">
            <span>{{ infoData[selectIndex].DateInfo.Day }}</span>
            <div class="date-msg">
              <div>{{ infoData[selectIndex].DateInfo.Week }}</div>
              <div>{{ infoData[selectIndex].DateInfo.Lunar }}</div>
            </div>
          </div>
          <div class="more">
            <a-button type="link" size="small" @click="userDrawer = true">
              详情 >
            </a-button>
            <a-drawer
              :title="infoData[selectIndex].DateInfo.Lunar"
              placement="right"
              :visible="userDrawer"
              :width="350"
              @close="userDrawer = false"
            >
              <div class="linkman-drawer-list">
                <div class="linkman-content">
                  <div
                    class="list-item flex"
                    v-for="(item, index) in infoData[selectIndex].List"
                    :key="index + item.StudentId"
                    @click="_skipSkim(item)"
                  >
                    <div>
                      <img class="item-icon" :src="item.Image" alt="" />
                      <span class="user-name">{{ item.StudentName }}</span>
                      <span class="time">{{ item.TheTime }}</span>
                    </div>
                    <div>
                      <a-checkbox
                        :checked="item.Status == 1 ? true : false"
                        @click="_setStatus(item, index)"
                      ></a-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </a-drawer>
          </div>
        </div>
        <div class="linkman-content">
          <div
            class="list-item flex"
            v-for="(item, index) in infoData[selectIndex].List"
            :key="index + item.StudentId"
            @click="_skipSkim(item)"
          >
            <div>
              <img class="item-icon" :src="item.Image" alt="" />
              <span class="user-name">{{ item.StudentName }}</span>
              <span class="time">{{ item.TheTime }}</span>
            </div>
            <div>
              <a-checkbox
                :checked="item.Status == 1 ? true : false"
                @click="_setStatus(item, index)"
              ></a-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'calendarData',
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      // 用户列表抽屉
      userDrawer: false,
      testId: '298d688e3265cf103e036be59304317e',
      cutList: ['前天', '昨天', '今天'],
      cutIndex: 2,
      selfDate: 1,
      selectIndex: 1,
      infoData: '',
      month: this.__moment__(new Date(), 'YYYY-MM'),
      weekList: [
        {
          name: '一',
          value: 1,
        },
        {
          name: '二',
          value: 2,
        },
        {
          name: '三',
          value: 3,
        },
        {
          name: '四',
          value: 4,
        },
        {
          name: '五',
          value: 5,
        },
        {
          name: '六',
          value: 6,
        },
        {
          name: '天',
          value: 0,
        },
      ],
      cutNum: 0,
    }
  },
  watch: {
    id: function (data) {
      this.infoData = ''
      this._info()
    },
    month: function () {
      this.infoData = ''
      this._info()
    },
  },
  mounted() {
    this.selfDate = new Date().getDate()
    this._info()
  },
  methods: {
    _skipSkim(item) {
      let data = {
        type: 'student',
        name: item.StudentName,
        id: item.StudentId,
      }
      window.parent.postMessage(data, '*')
    },
    _setStatus(data, i) {
      this.$axios.post(
        8000148,
        {
          Id: data.Id,
          Status: data.Status == 1 ? 0 : 1,
        },
        (res) => {
          if (res.data.code == 1) {
            this.infoData[this.selectIndex].List.map((item) => {
              if (item.Id == data.Id) {
                item.Status = data.Status == 1 ? 0 : 1
              }
            })
            if (data.Status == 1) {
              this.$set(
                this.infoData[this.selectIndex],
                'Num',
                this.infoData[this.selectIndex].Num - 1
              )
            } else {
              this.$set(
                this.infoData[this.selectIndex],
                'Num',
                this.infoData[this.selectIndex].Num + 1
              )
            }
          }
        }
      )
    },
    _selectDate(day, index) {
      if (day == '') return
      this.selfDate = day
      let i = -1
      this.infoData.map((item, index) => {
        if (day == item.DateInfo.Day) {
          i = index
        }
      })

      this.selectIndex = i
    },
    _info() {
      let self = this
      this.$axios.post(
        8000146,
        {
          SelUserId: this.id,
          Date: this.__moment__(this.month).format('YYYY-MM'),
        },
        (res) => {
          this.infoData = res.data.data
          let date =
            new Date(this.infoData[0].Date).getDay() == 0
              ? 6
              : new Date(this.infoData[0].Date).getDay() - 1
          this.cutNum = date
          for (let i = 0; i < date; i++) {
            self.infoData.unshift({
              Num: 0,
              List: [],
              Date: i + 'sfdfwex',
              DateInfo: {
                Day: '',
              },
            })
          }
          this._selectDate(this.selfDate)
        }
      )
    },
    _cutDate(i) {
      if (i == 1) {
        if (this.cutIndex == 0) return
        this.cutIndex != 0 ? (this.cutIndex -= 1) : (this.cutIndex = 0)
        let d =
          parseInt(this.selfDate) - 1 > 9
            ? parseInt(this.selfDate) - 1
            : '0' + parseInt(this.selfDate) - 1
        this._selectDate(d)
      } else {
        if (this.cutIndex == 2) return
        this.cutIndex != 2 ? (this.cutIndex += 1) : (this.cutIndex = 2)
        let d =
          parseInt(this.selfDate) + 1 > 9
            ? parseInt(this.selfDate) + 1
            : '0' + parseInt(this.selfDate) + 1
        this._selectDate(d)
      }
    },
  },
}
</script>
<style scoped lang="less">
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.linkman-drawer-list {
  padding: 0 20px;
  .linkman-content {
    .list-item {
      padding: 12px 0;
      border-bottom: 1px solid #f7f8f9;
      .item-icon {
        width: 40px;
        height: 40px;
        border-radius: 4px;
      }
      .user-name {
        color: #323233;
        font-size: 16px;
        margin: 0 8px;
      }
      .time {
        color: #999999;
        font-size: 17px;
      }
    }
  }
  .linkman-title {
    justify-content: space-between;
    .title {
      display: flex;
      align-items: center;
    }
    .date-msg {
      :first-child {
        color: #808080;
        font-size: 15px;
      }
      :last-child {
        font-size: 17px;
      }
    }
    span {
      font-size: 45px;
      margin-right: 10px;
    }
  }
}
.calendarData {
  .date-container {
    width: 95%;
    height: 88%;
    margin: 24px auto;
    border-radius: 6px;
    border: 2px solid #f5f5f5;
    .linkman-list {
      padding: 0 20px;
      padding-top: 0;
      border-left: 2px solid #f5f5f5;
      .linkman-content {
        width: 90%;
        height: 260px;
        overflow-y: scroll;
        padding-left: 30px;
        .list-item {
          padding: 12px 0;
          border-bottom: 1px solid #f7f8f9;
          .item-icon {
            width: 40px;
            height: 40px;
            border-radius: 4px;
          }
          .user-name {
            color: #323233;
            font-size: 16px;
            margin: 0 8px;
          }
          .time {
            color: #999999;
            font-size: 17px;
          }
        }
      }
      .linkman-title {
        justify-content: space-between;
        .title {
          display: flex;
          align-items: center;
        }
        .date-msg {
          :first-child {
            color: #808080;
            font-size: 15px;
          }
          :last-child {
            font-size: 17px;
          }
        }
        span {
          font-size: 45px;
          margin-right: 10px;
        }
      }
    }
    .date-plug {
      width: 370px;
      height: 340px;
      padding: 20px;
      .week-list {
        width: 95%;
        margin: 0 auto;
        .week-item {
          position: relative;
          width: 44px;
          height: 44px;
          text-align: center;
          display: inline-block;
          vertical-align: middle;
          color: #9cb2cd;
          font-size: 14px;
          cursor: pointer;
          .num {
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            border-radius: 50%;
            background: #ff8c8c;
            color: #fff;
            font-size: 8px;
          }
          &.active {
            background: #538fff;
            color: #fff;
            border-radius: 50%;
          }
        }
      }
      .plug-title {
        padding-left: 20px;
        margin-bottom: 10px;
        .now-date {
          div,
          span {
            display: inline-block;
            vertical-align: middle;
            user-select: none;
          }
          span {
            margin: 0 10px;
            font-size: 17px;
            color: #273142;
          }
          div {
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border: 1px solid #e6e9f0;
            color: #9cb2cd;
            border-radius: 8px;
            cursor: pointer;
          }
        }
        .date-text {
          position: relative;
          font-size: 20px;
          color: #333;
          .month {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }
      }
    }
  }
}
</style>
