<template>
    <div class="CounselorBoard">
        <div style="width: 170px;margin-bottom: 10px;">
			<a-select show-search placeholder="搜索名称" option-filter-prop="children" style="width: 100%" :filter-option="filterOption" @change="_searchChange" v-if="UserList && UserList.length">
				<a-select-option :value="item.Id" v-for="item in UserList" :key="item.Id">{{item.Name }}</a-select-option>

				<a-select-opt-group label="历史搜索">
					<a-select-option :value="item.Id" v-for="item in historyList" :key="'h'+item.Id">{{ item.Name }}</a-select-option>
				</a-select-opt-group>
			</a-select>
			<person v-if="showPerson && UserList.length ==0 " class="person" v-bind="personObj2" @getData="participatePerson2"></person>
        </div>
        <div class="container">
            <div class="content-left">
                <div class="left-item border-color">
                    <calendarData :id="searchId"></calendarData>
                </div>
                <!-- 客户数据 -->
                <div class="left-item border-color">
                    <div class="content-title flex">
                        <span class="title-text">客户数据</span>
                        <!-- <a-icon type="more" class="title-icon" /> -->
                    </div>
                    <div class="client-data">
                        <div style="display: inline-block;position:relative;width: 100%" :class="curContent == 1 ? 'showContent' : 'hideContent'">
                            <ul>
                                <li class="flex" v-for="(item) in _studentDataInit()" :key="item.name"
                                    @click="_getStudentList(2,item.name,'')">
                                    <div class="label-text">
                                        <span></span>{{ item.name }}
                                    </div>
                                    <div class="label-num">
                                        <span>{{ item.value }}</span>个
                                        <a-icon type="right"/>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div style="display: inline-block;position:relative;width: 100%"
                             :class="curContent == 2 ? 'showContent2' : 'hideContent2'">
                            <ul>
                                <li class="flex" v-for="(item,index) in _studentDataInit(2)"
									v-if="index < 10"
									:key="item.name"
                                    @click="_getStudentList(2,item.name,'')">
                                    <div class="label-text">
                                        <span></span>{{ item.name }}
                                    </div>
                                    <div class="label-num">
                                        <span>{{ item.value }}</span>个
                                        <a-icon type="right"/>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <a-icon class="left-btn" type="left-circle" @click="_curContent(1)"/>
                        <a-icon class="right-btn" type="right-circle" @click="_curContent(2)"/>
                        <div class="control-list">
                            <div class="control-item" :class="curContent == 1 ? 'active': ''"
                                 @click="_curContent(1)"></div>
                            <div class="control-item" :class="curContent == 2 ? 'active': ''"
                                 @click="_curContent(2)"></div>
                        </div>
                    </div>
                </div>
                <!-- 我的任务 -->
                <div class="left-item border-color" style="height: 320px">
                    <div class="content-title flex">
                        <span class="title-text">我的任务</span>
                    </div>
                    <div class="task-list">
                        <ul>
                            <li v-for="(item) in taskList" :key="'t'+item.Id" @click="_skipTask(item)">
                                <div class="item-label">{{ item.Name }}</div>
                                <div class="item-num">
                                    <span>{{ item.nohaneHandledCount }}</span> / {{ item.totalCount }}
                                </div>
                            </li>
                        </ul>
                        <div class="no-data" v-if="taskList == '' || taskList.length == 0">暂无数据</div>
                    </div>
                </div>
                <!-- 客户动态 -->
                <div class="left-item border-color" style="height: 1055px;overflow-y: scroll">
                    <div class="content-title flex">
                        <span class="title-text">客户动态</span>
                        <a-select style="width: 130px" @change="_studentHandleChange">
                            <a-select-option :value="item.FieldValue" v-for="item in studentHandleList" :key="item.FieldName">
                                {{ item.FieldName }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="dynamic-list">
<!--						@click="_skip('student',item.StudentName,item.StudentId)"-->
                        <div class="list-item flex" v-for="item in studentDynamicList" :key="'t'+item.Id" :class="item == 1 ? 'active' : ''" @click="_windowHref(item)">
                            <img class="item-icon" :src="item.Avatar" alt="">
                            <div class="item-content flex">
                                <div class="item-msg">
                                    <div class="item-title">【{{ item.TypeName }}】</div>
                                    <div class="op-content">
<!--                                        <span class="name">{{ item.UserName }}</span>-->
                                        {{ item.Describe }}
                                    </div>
                                </div>
                                <div class="create-date">{{ item.CreateTime }}</div>
                            </div>
                        </div>
                        <div class="no-data" v-if="studentDynamicList.length == 0">暂无数据</div>
                    </div>
                </div>
            </div>
            <div class="content-right">
                <!-- 目标 -->
                <div class="target-container border-color">
					<div class="content-title flex">
						<span class="title-text">目标</span>
						<a-month-picker style="width: 100px;height: 32px;position: relative;z-index: 1000" v-model="targetMonth" :format="'YYYY-MM'"/>
					</div>
					<div class="main" v-show="(targetData!= '' && targetData.length != 0) && targetData[targetData.length - 1].TargetNumber != 0 && targetData[targetData.length - 1].TargetNumber > 0">
						<div style="display: flex;justify-content: space-between;" v-if="targetData && targetData.length != 0">
							<div class="accomplish-num">
								<div class="label-text">{{ __moment__(targetMonth).format('MM月') }}已完成</div>
								<div class="num">{{ targetData[targetData.length - 1].Money }}</div>
							</div>
							<div class="target-num">
								<div class="label-text">{{ __moment__(targetMonth).format('MM月') }}目标值</div>
								<div class="num">{{ targetData[targetData.length - 1].TargetNumber }}</div>
							</div>
						</div>
						<div class="target-pic" id="target-pic"></div>
					</div>
					<div class="graph-title">{{picType == 0 ? '业绩目标' : picType == 1 ? '课耗目标' : '产品目标'}}
						<a-popover v-model="visible" trigger="click">
							<div slot="content">
								<ul>
									<li style="padding: 4px 0;" @click="_cutTarget(0)">业绩目标</li>
									<li style="padding: 4px 0;" @click="_cutTarget(1)">课耗目标</li>
									<li style="padding: 4px 0;" @click="_cutTarget(2)">产品目标</li>
								</ul>
							</div>
							<a-icon type="form" style="color: #538FFF;vertical-align: middle;margin-left: 4px;cursor: pointer"/>
						</a-popover>
					</div>
					<div class="no-data" v-if="(!targetData || targetData.length == 0) || targetData[targetData.length - 1].TargetNumber == 0 || targetData[targetData.length - 1].TargetNumber < 0">暂无数据</div>
					<div style="text-align: center;padding-top: 5px;">
						<a-button v-if="((!targetData || targetData.length == 0) || targetData[targetData.length - 1].TargetNumber == 0) && (UserList && UserList.length != 0)" @click="_skip('plan','销售计划',userInfo.uid)" type="primary">设置目标</a-button>
					</div>
                </div>
                <!-- 数据汇总 -->
                <div class="data-collect border-color">
                    <div class="content-title flex">
                        <span class="title-text">数据汇总</span>
                        <a-month-picker style="width: 100px" v-model="salesInfoMonth" :format="'YYYY-MM'"/>
                    </div>
                    <div class="tab-list">
                        <div class="tab" v-for="(value,key) in salesInfo.List" :key="key"
                             @click="_getStudentList(1,key,__moment__(salesInfoMonth).format('YYYY-MM'))">
                            <div class="tab-num">{{ value }}</div>
                            <div class="tab-label">{{ key }}</div>
                        </div>
                    </div>
                    <div v-if="!salesInfo.List" style="padding: 15px;">
                        <a-skeleton avatar :paragraph="{ rows: 6 }"/>
                    </div>
                </div>
                <!-- 销售漏斗 -->
                <div class="data-collect border-color" style="height: 540px;">
                    <div class="content-title flex">
                        <span class="title-text">销售漏斗</span>
                        <a-month-picker style="width: 100px" v-model="funnelMonth" :format="'YYYY-MM'"/>
                    </div>
                    <div class="market-content" v-show="funnelData && funnelData.List[0].pv != 0">
                        <div class="market-title">成交率（成交/体验）: <span>{{ funnelData.Rate }}</span></div>
                        <div id="container"></div>
                    </div>
                    <div class="no-data" v-if="!funnelData || funnelData.List[0].pv == 0">暂无数据</div>
                </div>
                <!-- 排行榜 -->
                <div class="data-collect border-color" style="height: auto;">
                    <div class="content-title flex">
                        <span class="title-text">排行榜</span>
                        <div class="flex">
                            <div style="width: 150px;margin-right: 10px;">
                                <person class="person" v-bind="personObj3" @getData="participatePerson3"></person>
                            </div>
                            <a-month-picker style="width: 100px;height: 32px;" v-model="rankListMonth" :format="'YYYY-MM'"/>
                        </div>
                    </div>
                    <div class="ranking-list">
                        <!-- 我的排行 -->
                        <div class="list-item flex" v-if="rankList.myrank" style="background: #F1FBFF">
                            <div>
                                <span class="num">{{ rankList.myrank.Number }}</span>
                                <div style="color: #707070;font-size: 12px">我的排名</div>
                            </div>
                            <div class="flex" style="flex : 1;border-bottom: 1px solid #F6F6F6;padding: 12px 20px;">
                                <div class="flex">
                                    <img class="avatar" :src="rankList.myrank.Avatar" alt="">
                                    <div>
                                        <div class="name">{{ rankList.myrank.UserName }}</div>
                                        <div class="centre">{{ rankList.myrank.School }}</div>
                                    </div>
                                </div>
                                <div class="money">{{ rankList.myrank.Summary }}</div>
                            </div>
                        </div>
                        <div class="list-item flex" v-for="(item,index) in rankList.data" :key="item.UserName">
                            <div>
                                <span class="num">{{ index + 1 }}</span>
                            </div>
                            <div class="flex" style="flex : 1;border-bottom: 1px solid #F6F6F6;padding: 12px 20px;">
                                <div class="flex">
                                    <img class="avatar" :src="item.Avatar" alt="">
                                    <div>
                                        <div class="name">{{ item.UserName }}</div>
                                        <div class="centre">{{ item.School }}</div>
                                    </div>
                                </div>
                                <div class="money">{{ item.Summary }}</div>
                            </div>
                        </div>
                        <div v-if="!rankList.data" style="padding: 15px;">
                            <a-skeleton avatar :paragraph="{ rows: 4 }"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a-drawer :title="drawerType" placement="right" :closable="false" :visible="studentVisible" width="1000"
                  @close="_studentonClose">
            <div class="padding: 15px;">
                <a-table :columns="tableColumns" :data-source="studentList" :pagination="false" :customRow="_rowClick">
                    <span slot="code" slot-scope="text, record,index">
                        {{ ((currentPage - 1) * 10) + index + 1 }}
                    </span>
                </a-table>
                <div style="padding: 20px 0;text-align: right" v-if="tableCount > 10">
                    <a-pagination v-model="currentPage" :defaultPageSize="10" :total="tableCount" @change="_paginationChange"/>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import {Chart,registerShape} from '@antv/g2';
import DataSet from '@antv/data-set';
import person from "@/components/SelectPersonnel/SelectPersonnel";
import calendarData from './calendarData.vue';

const {DataView} = DataSet;

export default {
    name: "CounselorBoard",
    components: {
        person, calendarData
    },
    data() {
        return {
            userInfo: JSON.parse(window.sessionStorage.getItem('userInfo')),
            monthFormat: 'YYYY/MM',
            studentHandleList: [],
            personObj3: {
                rank: 1, //职级 0为不选，1为选
                nums: 10, //选的数量
                department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                contacts: "", //常用联系人 不要常用联系人传空''
                selectArr: [], //要传入的数据
                placeholder: "组织架构"
            },
            personObj2: {
                rank: 1, //职级 0为不选，1为选
                nums: 1, //选的数量
                department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                contacts: "", //常用联系人 不要常用联系人传空''
                selectArr: [], //要传入的数据
                placeholder: "搜索名称"
            },
            visible: false,
            studentVisible: false,
            searchId: '',
            tableColumns: [
                {
                    title: '序号',
                    scopedSlots: {customRender: 'code'},
                    align: 'center'
                },
                {
                    title: '姓名',
                    dataIndex: 'StudentName',
                    key: 'StudentName',
                },
                {
                    title: '电话',
                    dataIndex: 'UserName',
                    key: 'UserName',
                },
                {
                    title: '所属中心',
                    dataIndex: 'SchoolName',
                    key: 'SchoolName',
                },
                {
                    title: '来源',
                    dataIndex: 'Source',
                    key: 'Source',
                },
                {
                    title: '顾问',
                    dataIndex: 'Person',
                    key: 'Person',
                },
                {
                    title: '创建时间',
                    dataIndex: 'CreateTime',
                    key: 'CreateTime',
                }
            ],
            UserList: [],
            historyList: [],
            monthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            dataCollect: 2,
            studentData: [],
            testId: '298d688e3265cf103e036be59304317e',
            //数据汇总
            salesInfo: '',
            salesInfoMonth: this.__moment__(new Date(), 'YYYY-MM'),
            //目标
            targetData: '',
            targetMonth: this.__moment__(new Date(), 'YYYY-MM'),
            picType: 0,
            //排行榜
            rankList: '',
            rankListMonth: this.__moment__(new Date(), 'YYYY-MM'),
            rankSearch: [],
            //销售漏斗
            funnelMonth: this.__moment__(new Date(), 'YYYY-MM'),
            funnelData: '',
            chart: '',
            //我的任务
            taskList: '',
            //客户动态
            studentDynamicList: '',
            studentList: [],
            drawerType: '',
            drawerTab: '',
            drawerMonth: '',
            tableCount: 0,
            currentPage: 1,
            curContent: 1,
			showPerson: false
        }
    },
    watch: {
        targetMonth: function () {
            this._targetData();
        },
        funnelMonth: function () {
            this._funnelData();
        },
        salesInfoMonth: function () {
            this._salesInfo();
        },
        rankListMonth: function () {
            this._rankList();
        }
    },
	created() {
		if(this.userInfo.name == '李泳章'){
			this.$set(this.userInfo,'uid','f1fecd3a703a14e9de29ef6963d51bbc');
		}
	},
	mounted() {
        //客户数据
        this._studentData();
        //数据汇总
        this._salesInfo();
        //排行榜
        this._rankList();
        //漏斗图
        this._funnelData();
        //获取选人下拉
        this.$axios.post(8000145, {}, res => {
            if (res.data.code == 1) {
                this.UserList = res.data.UserList || [];
				this.showPerson = true;
            }else{
				this.showPerson = false;
			}
        })
        //目标数据
        this._targetData();
        this.historyList = JSON.parse(window.localStorage.getItem('CounselorUserList'));
        //我的任务
        this._taskList();
        //客户动态
        this._studentDynamic('');
        //客户动态列表
        this.$axios.post(8000151, {}, res => {
            if (res.data.code == 1) {
                this.studentHandleList = res.data.data;
            }
        })
    },
    methods: {
		_windowHref(data){
			window.open(data.JumpUrl);
		},
		filterOption(input, option) {
			if (option.componentOptions.children[0].text) {
				return (
					option.componentOptions.children[0].text.indexOf(input) >= 0
				);
			}

		},
		_skip(type,name,id) {
			let data = {
				type: type,
				name: name,
				id: id
			};
			window.parent.postMessage(data, '*')
		},
        _skipTask(item) {
            let data = {
                type: 'custom',
                name: item.Name,
                id: item.Id
            };
            window.parent.postMessage(data, '*')
        },
        _studentHandleChange(data) {
            this.studentDynamicList = [];
            this._studentDynamic(data)
        },
        _curContent(i) {
            if (i == this.curContent) return;
            this.curContent = i;
        },
        _rowClick(record) {
            return {
                on: {
                    click: () => {
                        let data = {
                            type: 'student',
                            name: record.StudentName,
                            id: record.Id
                        };
                        window.parent.postMessage(data, '*')
                    }
                }
            }
        },
        _studentonClose() {
            this.studentList = [];
            this.studentVisible = false;
        },
        _paginationChange(data) {
            this.$axios.post(8000152, {
                UserId: this.searchId || this.userInfo.uid,
                Month: this.drawerMonth,
                Type: this.drawerType,
                Tab: this.drawerTab,
                Page: data,
                PageSize: 10
            }, res => {
                if (res.data.code == 1) {
                    this.studentList = res.data.data;
                }
            })
        },
        _getStudentList(Tab, Type, Month) {
            this.$axios.post(8000152, {
                UserId: this.searchId || this.userInfo.uid,
                Month: Month,
                Type: Type,
                Tab: Tab,
                Page: 1,
                PageSize: 10
            }, res => {
                if (res.data.code == 1) {
                    this.currentPage = 1;
                    this.tableCount = res.data.count;
                    this.drawerType = Type;
                    this.drawerTab = Tab;
                    this.drawerMonth = Month;
                    this.studentList = res.data.data;
                    this.studentVisible = true;
                }
            })
        },
        _studentDynamic(type) {
			this.studentDynamicList = [];
            this.$axios.post(8000149, {
                UserId: this.searchId || this.userInfo.uid,
                Type: type
            }, res => {
                if (res.data.code == 1) {
                    this.studentDynamicList = res.data.data;
                }
            })
        },
        _taskList() {
			this.taskList = [];
            this.$axios.post(8000150, {
                UserId: this.searchId || this.userInfo.uid
            }, res => {
                if (res.data.code == 1) {
                    this.taskList = res.data.data;
                }
            })
        },
        _cutTarget(i) {
            this.picType = i;
            this.visible = false;
            this._targetData();
        },
        _searchChange(data) {
            this.searchId = data;
            let list = JSON.parse(window.localStorage.getItem('CounselorUserList')) || [];
            const item = this.UserList.find(res => {
                return res.Id == data
            });
            const isNo = list.find(res => {
                return res.Id == item.Id
            }) || [];
            if (isNo.length == 0) {
                list.unshift(item);
                this.historyList = list;
                window.localStorage.setItem('CounselorUserList', JSON.stringify(list))
            }
            //目标
            this._targetData();
			this.picType = 0;
            //任务
            this._taskList();
			//客户数据
			this._studentData();
			//数据汇总
			this._salesInfo();
			//漏斗图
			this._funnelData();
			//客户动态
			this._studentDynamic('');
        },
        participatePerson2(data) {
			if (data.length != 0) {
				this.searchId = data[0].id
			}else{
				this.searchId = '';
			}
			//目标
			this._targetData();
			this.picType = 0;
			//任务
			this._taskList();
			//客户数据
			this._studentData();
			//数据汇总
			this._salesInfo();
			//漏斗图
			this._funnelData();
			//客户动态
			this._studentDynamic('');
        },
        participatePerson3(data) {
            this.rankSearch = data;
            this._rankList();
        },
        _targetData() {
			let self = this;
			this.targetData = [];
            this.$axios.post(8000147, {
                SelUserId: this.searchId,
                TheDate: this.__moment__(this.targetMonth).format('YYYY-MM'),
                Type: this.picType //（0:业绩，1：课耗，2:产品）
            }, res => {
                if (res.data.code == 1) {
                    this.targetData = res.data.data;
					document.getElementById('target-pic').innerHTML = "";
					setTimeout(()=>{
						//时速表
						self._initEcharts();
					},300)
                }
            })
        },
        _funnelData() {
            this.$axios.get(8000143, {
                UserId: this.searchId || this.userInfo.uid,
                Month: this.__moment__(this.funnelMonth).format('YYYY-MM')
            }, res => {
                if (res.data.code == 1) {
                    document.getElementById('container').innerHTML = "";
					this.funnelData = [];
                    this.funnelData = res.data.data.Funnel;
                    if (this.funnelData.List[0].pv != 0) {
						setTimeout(()=>{
							this._initChart();
						},300)
                    }
                }
            })
        },
        _rankList() {
            this.$axios.get(8000144, {
                Month: this.__moment__(this.rankListMonth).format('YYYY-MM'),
                OrgId: this.rankSearch.length != 0 ? this.rankSearch[0].id : ''
            }, res => {
                if (res.data.code == 1) {
                    this.rankList = res.data;
                }
            })
        },
        _salesInfo() {
			this.salesInfo = [];
            this.$axios.get(8000143, {
                UserId: this.searchId || this.userInfo.uid,
                Month: this.__moment__(this.salesInfoMonth).format('YYYY-MM')
            }, res => {
                if (res.data.code == 1) {
                    this.salesInfo = res.data.data;
                }
            })
        },
        _studentDataInit(i) {
            let data = [];
            let data2 = [];
            for (const key in this.studentData.List) {
                if (data.length < 10) {
                    data.push({name: key, value: this.studentData.List[key]})
                } else {
                    data2.push({name: key, value: this.studentData.List[key]})
                }
            }
            return i === 2 ? data2 : data;
        },
        _studentData() {
			this.studentData = [];
            this.$axios.get(8000142, {
                UserId: this.searchId || this.userInfo.uid
            }, res => {
                if (res.data.code == 1) {
                    this.studentData = res.data.data;
                }
            })
        },
        _initChart() {
            const self = this;
            const dv = new DataView().source(self.funnelData.List);
            dv.transform({
                type: 'map',
                callback(row) {
                    row.percent = row.pv / self.funnelData.List[0].pv;
                    return row;
                },
            });
            const data = dv.rows;
            self.chart = new Chart({
                container: 'container',
                autoFit: true,
                height: 500,
                padding: [20, 120, 95],
            });
            self.chart.data(data);
            self.chart.axis(false);
            self.chart.tooltip({
                showTitle: false,
                showMarkers: false,
                itemTpl:
                        '<li style="margin-bottom:4px;list-style-type:none;padding: 0;">' +
                        '<span style="background-color:{color};" class="g2-tooltip-marker"></span>' +
                        '{name}<br/>' +
                        '<span style="padding-left: 16px;line-height: 16px;">浏览人数：{pv}</span><br/>' +
                        '<span style="padding-left: 16px;line-height: 16px;">占比：{percent}</span><br/>' +
                        '</li>',
            });
            self.chart.coordinate('rect').transpose().scale(1, -1);
            self.chart.interval().adjust('symmetric').position('action*percent').shape('funnel').color('action', ['#FF6785', '#FE7F7D', '#FE9875', '#FEAF6D', '#FEC765']).label(
                    'action*pv', (action, pv) => {
                        return {
                            content: `${action} ${pv}`,
                        };
                    },
                    {
                        offset: 35,
                        labelLine: {
                            style: {
                                lineWidth: 1,
                                stroke: 'rgba(0, 0, 0, 0.15)',
                            },
                        },
                    }
            )
                    .tooltip('action*pv*percent', (action, pv, percent) => {
                        return {
                            name: action,
                            percent: +percent * 100 + '%',
                            pv,
                        };
                    })
                    .animate({
                        appear: {
                            animation: 'fade-in'
                        },
                        update: {
                            annotation: 'fade-in'
                        }
                    });

            self.chart.interaction('element-active');

            self.chart.on('beforepaint', () => {
                self.chart.annotation().clear(true);
                const chartData = self.chart.getData();
                // 中间标签文本
                chartData.forEach((obj) => {
                    self.chart.annotation().text({
                        top: true,
                        position: {
                            action: obj.action,
                            percent: 'center',
                        },
                        content: +(obj.percent * 100).toFixed(2) + '%', // 显示的文本内容
                        style: {
                            stroke: null,
                            fill: '#000',
                            fontSize: 18,
                            textAlign: 'center',
                        },
                    });
                });
            });
            self.chart.render();
        },
        _initEcharts() {
            let self = this;
            registerShape('point', 'pointer', {
                draw(cfg, container) {
                    const group = container.addGroup();
                    const center = this.parsePoint({x: 0, y: 0}); // 获取极坐标系下画布中心点
                    // 绘制指针
                    group.addShape('line', {
                        attrs: {
                            x1: center.x,
                            y1: center.y,
                            x2: cfg.x,
                            y2: cfg.y,
                            stroke: '181C25',
                            lineWidth: 5,
                            lineCap: 'round',
                        },
                    });
                    group.addShape('circle', {
                        attrs: {
                            x: center.x,
                            y: center.y,
                            r: 9.75,
                            stroke: '181C25',
                            lineWidth: 4.5,
                            fill: '#fff',
                        },
                    });

                    return group;
                },
            });
			const data = [{value: self.targetData[self.targetData.length -1].Money > self.targetData[self.targetData.length -1].TargetNumber ? self.targetData[self.targetData.length -1].TargetNumber : self.targetData[self.targetData.length -1].Money}];

			const chart = new Chart({
                container: 'target-pic',
                autoFit: true,
                height: 300,
                padding: [0, 0, 30, 0],
            });
			chart.data(data);
            chart.scale('value', {
                min: 0,
                max: self.targetData[self.targetData.length -1].TargetNumber,
                tickInterval: 1,
            });
            chart.coordinate('polar', {
                startAngle: (-9 / 8) * Math.PI,
                endAngle: (1 / 8) * Math.PI,
                radius: 0.75,
            });

            //chart.axis('1', false);
            chart.axis('value', {
                line: null,
                label: {
                    offset: -36,
                    style: {
						display: 'none',
                        fontSize: 0,
                        textAlign: 'center',
                        textBaseline: 'middle',
                    },
                },
                subTickLine: null,
                tickLine: null,
                grid: null,
            });
            chart.legend(false);
            chart
                    .point()
                    .position('value*1')
                    .shape('pointer')
                    .color('#1890FF')
                    .animate({
                        appear: {
                            animation: 'fade-in'
                        }
                    });

// 绘制仪表盘背景
            chart.annotation().arc({
                top: false,
                start: [0, 1],
                end: [self.targetData[self.targetData.length -1].TargetNumber, 1],
                style: {
                    // 底灰色
                    stroke: '#DEE1E8',
                    lineWidth: 18,
                    lineDash: null,
                },
            });

// 绘制指标
            chart.annotation().arc({
                start: [0, 1],
                end: [data[0].value, 1],
                style: {
                    stroke: '#FD7E22',
                    lineWidth: 18,
                    lineDash: null,
                },
            });
// 绘制指标数字
            chart.annotation().text({
                position: ['50%', '85%'],
                content: self.targetData[self.targetData.length -1].TargetNumber == 0 ? 0 : (self.targetData[self.picType].Money / self.targetData[self.targetData.length -1].TargetNumber * 100).toFixed(2) + '%',
                style: {
                    fontSize: 24,
                    fill: '#4D5158',
                    textAlign: 'center',
                },
            });
            chart.annotation().text({
                position: ['50%', '90%'],
                content: '合格率',
                style: {
                    fontSize: 14,
                    fill: '#9B9FA5',
                    textAlign: 'center',
                },
                offsetY: 13,
            });
            chart.render();
        }
    }
}
</script>

<style scoped lang="less">
.CounselorBoard {
    width: 100%;
    height: 100%;

    .showContent {
        left: 0;
        transition: all .3s linear;
    }

    .hideContent {
        left: -100%;
        transition: .3s linear;
    }

    .showContent2 {
        left: -100%;
        transition: all .3s linear;
    }

    .hideContent2 {
        left: 0;
        transition: all .3s linear;
    }

    .no-data {
        padding: 20px 0;
        font-size: 20px;
        color: #ccc;
        text-align: center;
    }

    .container {
        width: 100%;
        display: flex;

        .content-left {
            flex: 1;
            margin-right: 10px;

            .left-item {
                width: 100%;
                height: 390px;
                margin-bottom: 10px;
                overflow: hidden;

                .dynamic-list {
                    width: 95%;
                    margin: 0 auto;

                    .list-item {
                        padding: 15px 10px 15px 15px;

                        .item-content {
                            flex: 1;

                            .item-msg {
                                color: #212223;
                                font-size: 17px;

                                .op-content {
                                    margin-top: 2px;
                                    font-size: 15px;
                                    color: #666;

                                    .name {
                                        color: #0080FF;
                                    }
                                }
                            }

                            .create-date {
                                white-space: nowrap;
                                margin-left: 10px;
                                font-size: 14px;
                                color: #909191;
                            }
                        }

                        .item-icon {
                            width: 45px;
                            height: 45px;
                            margin-right: 20px;
                            border-radius: 50%;
                        }

                        &.active {
                            background: #F7F8F9;
                        }
                    }
                }

                .task-list {
                    ul {
                        width: 94%;
                        margin: 0 auto;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        li {
                            width: 32%;
                            margin-bottom: 15px;
                            padding: 12px;
                            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
                            color: #858585;
                            cursor: pointer;

                            .item-label {
                                font-size: 15px;
                            }

                            .item-num {
                                font-size: 15px;

                                span {
                                    color: #538FFF;
                                }
                            }
                        }
                    }
                }

                .client-data {
                    //width: 100%;
                    height: calc(~'100% - 55px');
                    white-space: nowrap;
                    position: relative;

                    .control-list {
                        position: absolute;
                        bottom: 10px;
                        left: 50%;
                        transform: translateX(-50%);

                        .control-item {
                            display: inline-block;
                            width: 35px;
                            height: 3px;
                            background: #D0D0D0;
                            margin-left: 4px;
                            cursor: pointer;

                            &.active {
                                background: #0080FF;
                            }
                        }
                    }

                    .left-btn {
                        position: absolute;
                        top: 50%;
                        left: 15px;
                        transform: translateY(-50%);
                        font-size: 26px;
                        color: #777;
                    }

                    .right-btn {
                        position: absolute;
                        top: 50%;
                        right: 15px;
                        transform: translateY(-50%);
                        font-size: 26px;
                        color: #777;
                    }

                    ul {
                        // position: absolute;
                        // top: 0;
                        // left: 0;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        width: 100%;
                        padding: 0 60px;

                        li {
                            width: 46%;
                            padding: 10px 0;
                            margin-bottom: 12px;
                            font-size: 16px;
                            color: #848484;
                            border-bottom: 1px solid #F6F6F6;

                            .label-text {
                                span {
                                    display: inline-block;
                                    vertical-align: middle;
                                    width: 8px;
                                    height: 8px;
                                    border-radius: 50%;
                                    margin-right: 8px;
                                    background: #B557F6;
                                }
                            }

                            .label-num {
                                span {
                                    color: #1890FF;
                                    margin-right: 8px;
                                    font-size: 18px;
                                    font-weight: bold;
                                }

                                i {
                                    margin-left: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .content-right {
            width: 590px;

            .target-container, .data-collect {
                position: relative;
                width: 100%;
                height: 390px;
                margin-bottom: 10px;
                overflow: hidden;

                .ranking-list {
                    margin: 0 auto;

                    .list-item {
                        padding: 0 20px;
                    }

                    .money {
                        font-size: 22px;
                        color: #333;
                    }

                    .centre {
                        font-size: 14px;
                        color: #999;
                    }

                    .name {
                        font-size: 16px;
                        color: #333;
                    }

                    .num {
                        display: inline-block;
                        width: 40px;
                        color: #333;
                        font-size: 20px;
                        text-align: center;
                    }

                    .avatar {
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;
                        margin-right: 20px;
                    }
                }

                .market-content {
                    .market-title {
                        width: 94%;
                        margin: 0 auto;
                        color: #BCBCBC;
                        font-size: 16px;

                        span {
                            font-size: 18px;
                            color: #FFA6B5;
                            font-weight: bold;
                        }
                    }
                }

                .tab-list {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 10px 20px;

                    .tab {
                        width: 120px;
                        margin-bottom: 25px;
                        margin-left: 15px;

                        .tab-num {
                            color: #333;
                            font-size: 20px;
                        }

                        .tab-label {
                            color: #ADADAD;
                            font-size: 15px;
                        }
                    }
                }

                .graph-title {
                    position: absolute;
                    bottom: 25px;
                    left: 50%;
                    transform: translateX(-50%);
                    color: #333;
                    font-size: 17px;
                    font-weight: bold;
                }

                .main {
                    position: relative;
                    padding: 10px 70px;

                    .target-pic {
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 100%;
                        height: 300px;
                    }

                    .label-text {
                        font-size: 16px;
                        color: #B5B5B5;
                    }

                    .num {
                        font-size: 22px;
                        color: #3F3F3F;
                    }
                }
            }
        }

        .content-title {
            padding: 15px;

            .title-text {
                font-size: 17px;
                color: #333;
                font-weight: bold;
            }

            .title-icon {
                position: relative;
                padding-left: 10px;
                font-size: 20px;
                cursor: pointer;
                color: #666;
                z-index: 100;
            }
        }

        .border-color {
            background: #fff;
            border-radius: 4px;
            border: 1px solid #E4E8EB;
        }
    }
}
</style>